import CachedIcon from '@mui/icons-material/Cached'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import {Autocomplete, Button, Chip, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import {envImagePath} from 'GLOBAL/envVariables'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getInstallList, imageDownload, imageRefresh} from 'utils/apiCalling/api'
import {globalConst, globalText} from 'utils/const/globalConst'
import {installDevicedBmToolTip} from 'utils/toolTips/toolTipText'

const Device = (props: any) => {
  const {asSubmit, set, setSelected} = props
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [installableList, setInstallableList] = useState<any>(null)
  const [selectInstallType, setSelectInstallType] = useState<any>(null)
  const getInstallListCall = () => {
    getInstallList(userInfo, 'installDevice', null, {
      success: (res: any) => {
        setInstallableList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }
  useEffect(() => {
    getInstallListCall()
  }, [])

  const imageRefreshCall = async () => {
    imageRefresh(asSubmit, {
      success: (res: any) => {
        set((prev: any) => ({
          ...prev,
          imei: res?.imei,
          idxDate: res?.idxDate,
          filePath: res?.filePath,
          createAt: res?.createAt,
          rssiStatus: res?.rssiStatus,
          temperature: res?.temperature,
        }))
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }

  console.log('as', asSubmit)

  return (
    <div>
      <Autocomplete
        fullWidth
        id='choose-device'
        options={installableList}
        className={'px-0'}
        disableClearable={true}
        clearText={'초기화'}
        noOptionsText={'검색 결과가 없습니다.'}
        getOptionLabel={(option: any) => `${option?.serialNumber} - ${option?.deviceTypeName}`}
        onChange={(e, rowValue: any) => {
          set((prev: any) => ({
            ...prev,
            brightness: 32,
            contrast: 255,
            createAt: rowValue?.createAt,
            deviceType: rowValue?.deviceType,
            deviceTypeName: rowValue?.deviceTypeName,
            filePath: rowValue?.filePath,
            imei: rowValue?.imei,
            idxDate: rowValue?.idxDate,
            serialNumber: rowValue?.serialNumber,
            rssiStatus: rowValue?.rssiStatus,
            temperature: rowValue?.temperature,
            changeFlag: true,
          }))
        }}
        sx={{fontSize: '1.5rem', color: '#fff'}}
        renderInput={(params) => (
          <div className={'d-flex gap-3'}>
            <TextField
              {...params}
              fullWidth
              label='단밀기 선택'
              focused
              placeholder={'단말기를 선택해주세요.'}
            />
          </div>
        )}
      />
      {asSubmit?.changeFlag && (
        <Box className={'my-2 p-2 border border-gray-400 rounded-2'}>
          {asSubmit?.deviceType === 'analog' && (
            <div className={'text-center'}>
              <div className={'w-100 d-grid justify-content-center'} style={{height: 144}}>
                <img
                  // src={'https://meterarium.com:5280' + asSubmit?.filePath}
                  src={`${envImagePath}${asSubmit?.filePath}`}
                  alt={'device'}
                  style={{width: 320}}
                />
              </div>
              <div className={'d-flex w-100 justify-content-start align-items-end mt-2 gap-2'}>
                <Chip
                  label={`전파 세기 : ${asSubmit?.rssiStatus}dBm `}
                  color={
                    Number(asSubmit?.rssiStatus) < globalConst.rssiWarningdBm ? 'error' : 'success'
                  }
                  variant={'filled'}
                  className={'fs-4'}
                />

                {Number(asSubmit?.temperature || 99) < 70 && (
                  <Chip
                    label={`온도 : ${asSubmit?.temperature}°C`}
                    color={'success'}
                    variant={'filled'}
                    className={'fs-4'}
                  />
                )}
              </div>
              <div className={'d-flex w-100 justify-content-end align-items-end mt-2'}>
                <span className={'text-end fs-3 fw-bold'}>
                  {dayjs(asSubmit?.createAt).format('YY.MM.DD HH시 mm분 ss초')}
                </span>
              </div>
              <div className={'alert alert-dark mt-2'}>
                <span className={'fs-3 fw-bold'}>설치 방식을 선택해주세요</span>
                <div className={'d-flex justify-content-around align-items-end mt-2'}>
                  <Chip
                    label={`${globalText.installInside}`}
                    color={'primary'}
                    variant={selectInstallType === 'N' ? 'filled' : 'outlined'}
                    className={'fs-4'}
                    onClick={() => {
                      setSelectInstallType('N')
                      setSelected(true)
                    }}
                  />

                  <Chip
                    label={`${globalText.installOutside}`}
                    color={'primary'}
                    variant={selectInstallType === 'OUT' ? 'filled' : 'outlined'}
                    className={'fs-4'}
                    onClick={() => {
                      setSelectInstallType('OUT')
                      setSelected(true)
                    }}
                  />
                </div>
              </div>

              {selectInstallType != null &&
                (asSubmit?.rssiStatus && Number(asSubmit?.rssiStatus) < globalConst.rssiWarningdBm
                  ? installDevicedBmToolTip(selectInstallType)
                  : installDevicedBmToolTip('success'))}

              <div className={'my-2 d-flex gap-2'}>
                <Button
                  variant={'contained'}
                  color={'warning'}
                  fullWidth
                  startIcon={<CachedIcon />}
                  onClick={() => imageRefreshCall()}
                >
                  새로고침
                </Button>
                <Button
                  variant={'contained'}
                  color={'success'}
                  fullWidth
                  startIcon={<DownloadDoneIcon />}
                  onClick={async () =>
                    await imageDownload(asSubmit?.filePath, 32, 255, 0, asSubmit?.imei)
                  }
                >
                  다운로드
                </Button>
              </div>
            </div>
          )}
          {asSubmit?.deviceType === 'digital' && (
            <>
              <h5 className={'text-center text-danger'}>디지털 단말기입니다.</h5>
              <div className={'d-flex justify-content-between align-items-end'}>
                <Chip
                  label={asSubmit?.rssiStatus}
                  color={'secondary'}
                  variant={'outlined'}
                  className={'fs-4'}
                />
                <span className={'text-end'}>
                  {dayjs(asSubmit?.createAt).format('YY.MM.DD HH시 mm분 ss초')}
                </span>
              </div>
            </>
          )}
        </Box>
      )}
    </div>
  )
}

export default Device
