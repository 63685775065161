import {Help as HelpIcon} from '@mui/icons-material'
import SquareIcon from '@mui/icons-material/Square'
import {Fade} from '@mui/material'
import {envBackHost} from 'GLOBAL/envVariables'
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import {customerLeakageFilter} from 'pages/customer/dataConfig/declearFilters'
import {MouseEvent, useEffect, useState} from 'react'
import {Bar, Doughnut} from 'react-chartjs-2'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {normalAlert} from 'utils/CustomAlert/Alert'
import {entimoreController, leakModelController} from 'utils/const/globalConst'
import {CustomWidthTooltip} from 'utils/designSytem/CustomTooltip'
import {windowWidthResize} from 'utils/func/justUtils'
import {customerLeakageFilterType} from 'utils/interface/customerType'
import {toolTipText} from 'utils/toolTips/toolTipText'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const DashboardChart = () => {
  const [filter, setFilter] = useState<customerLeakageFilterType>(customerLeakageFilter)
  const [customerInfoFilter, setCustomerInfoFilter] = useState<any>({
    searchOption: {
      customerNumber: false,
      customerName: false,
      address: false,
      managerName: false,
      memo: false,
      serialNumber: false,
      installerName: false,
    },
    searchData: '',
    deviceStatus: 'all',
    efosStatus: 'all',
    deviceType: 'all',
    batteryStatus: 'all',
    rowPerPage: 30,
    page: 1,
  })

  const [chartData, setChartData] = useState<any>({
    deviceStatus: {
      bad: 0,
      deviceBad: 0,
      deviceComplete: 0,
      deviceUpdate: 0,
      digitalMeterBad: 0,
      efosBad: 32,
      imageProcess: null,
      imageProcessCount: 0,
      normal: 2371,
    },
    batteryStatus: null,
    deviceTypeStatus: null,
    frozenStatus: [
      {freezeWarningCount: 0, freezeWarningTypeName: '양호'},
      {freezeWarningCount: 0, freezeWarningTypeName: '주의'},
      {freezeWarningCount: 0, freezeWarningTypeName: '위험'},
      {freezeWarningCount: 0, freezeWarningTypeName: '매우위험'},
    ],
    abnormalSatus: null,
  })

  const officeInfo = useSelector((state: any) => state.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const DeviceStatusdata = {
    labels: ['정상', '검침영상플레이어', '원격검침단말기', '디지털계량기'],
    datasets: [
      {
        label: '# of Votes',
        data: [
          chartData?.deviceStatus?.normal,
          chartData?.deviceStatus?.efosBad,
          chartData?.deviceStatus?.deviceBad,
          chartData?.deviceStatus?.digitalMeterBad,
        ],
        backgroundColor: [
          'rgba(54, 162, 235, 0.7)',
          'rgba(137,35,245,0.7)',
          'rgba(255, 99, 132, 0.7)',
          'rgba(75, 192, 192, 0.7)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(137,35,245,1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
  const batteryChartData = {
    labels: ['정상', '점검'],
    datasets: [
      {
        label: '# of Votes',
        data: [chartData?.batteryStatus?.normal, chartData?.batteryStatus?.bad],
        backgroundColor: ['rgba(54, 162, 235, 0.7)', 'rgba(255, 99, 132, 0.7)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  }
  const DeviceTypeData = {
    labels: chartData?.deviceTypeStatus?.map((el: any) => el?.deviceTypeName),
    datasets: [
      {
        label: '# of Votes',
        data: chartData?.deviceTypeStatus?.map((el: any) => el?.typeCount),
        backgroundColor: ['rgba(252,98,9,0.7)', 'rgba(75, 192, 192, 0.7)'],
        borderColor: ['rgba(252,98,9,1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  }

  const freezeWarningData = {
    labels: chartData?.frozenStatus?.map((el: any) => el?.freezeWarningTypeName),
    datasets: [
      {
        label: '# of Votes',
        // data: chartData?.frozenStatus?.map((el: any) => el?.typeCount),
        data: chartData?.frozenStatus?.map((el: any) => el?.freezeWarningCount),
        backgroundColor: [
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 215, 0, 0.7)',
          'rgba(255, 165, 0, 0.7)',
          'rgba(210, 105, 30, 0.7)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 215, 0, 1)',
          'rgba(255, 165, 0, 1)',
          'rgba(210, 105, 30, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const abnormalData: any = {
    labels: chartData?.abnormalStatus?.map((el: any) => el?.typeName),
    datasets: [
      {
        label: '# of Votes',
        data: chartData?.abnormalStatus?.map((el: any) => el?.count),
        backgroundColor: ['rgba(54, 162, 235, 0.7)', 'rgba(255, 99, 132, 0.7)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  }

  const DeviceStatusdataTotal = DeviceStatusdata?.datasets[0]?.data?.reduce(
    (acc, val) => acc + val,
    0
  )
  const batteryChartDataTotal = batteryChartData?.datasets[0]?.data?.reduce(
    (acc, val) => acc + val,
    0
  )
  const DeviceTypeDataTotal = DeviceTypeData?.datasets[0]?.data?.reduce(
    (acc: any, val: any) => acc + val,
    0
  )
  const freezeWaringDataTotal = freezeWarningData?.datasets[0]?.data?.reduce(
    (acc: any, val: any) => acc + val,
    0
  )
  const abnormalDataTotal = abnormalData?.datasets[0]?.data?.reduce(
    (acc: any, val: any) => acc + val,
    0
  )

  const deviceChartOptions = {
    responsive: true,
    aspectRatio: 1,
    width: 100,
    onClick(event?: MouseEvent, activeElements?: Array<{}>): any {
      //should change
      if (activeElements && activeElements.length > 0) {
        const firstElement: any = activeElements[0]

        // 'index' 키로 된 값에 접근
        const indexValue = firstElement?.index
        if (userInfo?.accountType?.includes('sys')) {
          navigate('/customer/info', {
            state: {
              deviceStatus: DeviceStatusdata?.labels[indexValue] === '정상' ? 'true' : 'false',
            },
          })
        } else if (userInfo?.accountType?.includes('gov_admin')) {
          navigate('/customer/customer-status', {
            state: {
              deviceStatus: DeviceStatusdata?.labels[indexValue] === '정상' ? 'true' : 'false',
            },
          })
        } else {
          normalAlert('권한이 없습니다.')
          return
        }
      }
    },
    cursor: 'pointer',
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return context?.raw + '건'
          },
          title: function (context: any) {
            return context[0].label
          },
        },
        bodyFont: {
          size: windowWidthResize(window.innerWidth),
        },
        bodyAlign: 'center',
        titleFont: {
          size: windowWidthResize(window.innerWidth),
        },
        titleAlign: 'center',
        titleMarginBottom: 10,
        backgroundColor: 'rgba(37,37,37,0.75)',
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          font: {
            size: windowWidthResize(window.innerWidth),
          },
        },
      },
      title: {
        display: true,
        text: `통신상태 점검 현황 ( 총 ${DeviceStatusdataTotal}건 )`,
        font: {
          size: windowWidthResize(window.innerWidth),
        },
        position: 'top',
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'center',
        anchor: 'center',
        font: {
          size: windowWidthResize(window.innerWidth),
          weight: 'bold',
        },
        formatter: function (value: any, context: any) {
          const percentage = (value / DeviceStatusdataTotal) * 100 // 비율을 계산

          if (percentage >= 5) {
            return value + '건'
          } else {
            return null
          }
        },
      },
    },
  }
  const batteryChartOptions = {
    responsive: true,
    aspectRatio: 1,
    onClick(event?: MouseEvent, activeElements?: Array<{}>): any {
      if (activeElements && activeElements.length > 0) {
        const firstElement: any = activeElements[0]

        // 'index' 키로 된 값에 접근
        const indexValue = firstElement?.index
        if (userInfo?.accountType?.includes('sys')) {
          navigate('/customer/info', {
            state: {
              batteryStatus: batteryChartData?.labels[indexValue] === '정상' ? 'true' : 'false',
            },
          })
        } else if (userInfo?.accountType?.includes('gov_admin')) {
          navigate('/customer/customer-status', {
            state: {
              batteryStatus: batteryChartData?.labels[indexValue] === '정상' ? 'true' : 'false',
            },
          })
        } else {
          normalAlert('권한이 없습니다.')
          return
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return context?.raw + '건'
          },
          title: function (context: any) {
            return context[0].label
          },
        },
        bodyFont: {
          size: windowWidthResize(window.innerWidth),
        },
        bodyAlign: 'center',
        bodyClassName: 'align-self-center mx-2',
        titleFont: {
          size: windowWidthResize(window.innerWidth),
        },
        titleAlign: 'center',
        titleMarginBottom: 10,
        backgroundColor: 'rgba(37,37,37,0.75)',
      },
      title: {
        display: true,
        text: `배터리 현황 ( 총 ${batteryChartDataTotal}건 )`,
        font: {
          size: windowWidthResize(window.innerWidth),
        },
        position: 'top',
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'center',
        anchor: 'center',
        font: {
          size: windowWidthResize(window.innerWidth),
          weight: 'bold',
        },
        formatter: function (value: any, context: any) {
          const percentage = (value / batteryChartDataTotal) * 100 // 비율을 계산

          if (percentage >= 5) {
            return value + '건' // 5% 이상이면 라벨 표시
          } else {
            return null // 5% 미만이면 라벨 표시하지 않음
          }
        },
      },
    },
  }
  const deviceTypeCahrtOptions = {
    responsive: true,
    aspectRatio: 1,
    onClick(event?: MouseEvent, activeElements?: Array<{}>): any {
      if (activeElements && activeElements.length > 0) {
        const firstElement: any = activeElements[0]

        // 'index' 키로 된 값에 접근
        const indexValue = firstElement?.index
        if (userInfo?.accountType?.includes('sys')) {
          navigate('/customer/info', {
            state: {
              deviceType:
                DeviceTypeData?.labels[indexValue] === '디지털이미지' ? 'analog' : 'digital',
            },
          })
        } else if (userInfo?.accountType?.includes('gov_admin')) {
          navigate('/customer/customer-status', {
            state: {
              deviceType:
                DeviceTypeData?.labels[indexValue] === '디지털이미지' ? 'analog' : 'digital',
            },
          })
        } else {
          normalAlert('권한이 없습니다.')
          return
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return context?.raw + '건'
          },
          title: function (context: any) {
            return context[0].label
          },
        },
        bodyFont: {
          size: windowWidthResize(window.innerWidth),
        },
        bodyAlign: 'center',
        bodyClassName: 'align-self-center mx-2',
        titleFont: {
          size: windowWidthResize(window.innerWidth),
        },
        titleAlign: 'center',
        backgroundColor: 'rgba(37,37,37,0.75)',
      },
      title: {
        display: true,
        text: `원격검침 현황 ( 총 ${DeviceTypeDataTotal}건 )`,
        font: {
          size: windowWidthResize(window.innerWidth),
        },
        position: 'top',
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'center',
        anchor: 'center',
        font: {
          size: windowWidthResize(window.innerWidth),
          weight: 'bold',
        },
        formatter: function (value: any, context: any) {
          const percentage = (value / DeviceTypeDataTotal) * 100 // 비율을 계산

          if (percentage >= 5) {
            return value + '건' // 5% 이상이면 라벨 표시
          } else {
            return null // 5% 미만이면 라벨 표시하지 않음
          }
        },
      },
    },
  }

  const FreezeTitle = () => {
    return (
      <div className='d-flex mt-3 align-items-center'>
        <div
          className={'fw-bold'}
          style={{
            fontSize: windowWidthResize(window.innerWidth),
            fontWeight: 'bold',
            color: '#666666',
          }}
        >
          동파경보 현황 ( 총 {freezeWaringDataTotal}건 )
        </div>
        <CustomWidthTooltip
          title={toolTipText.dashboardFreezeWarning.text}
          TransitionComponent={Fade}
          TransitionProps={{timeout: 600}}
          placement={'right-start'}
          arrow={true}
          sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
        >
          <HelpIcon
            color={'primary'}
            sx={{fontSize: '24px'}}
            className={'ms-4 mb-2 align-self-center'}
          />
        </CustomWidthTooltip>
      </div>
    )
  }

  const freezeWaringCahrtOptions = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return context?.raw + '건'
          },
          title: function (context: any) {
            return context[0].label
          },
        },
        bodyFont: {
          size: windowWidthResize(window.innerWidth),
        },
        bodyAlign: 'center',
        bodyClassName: 'align-self-center mx-2',
        titleFont: {
          size: windowWidthResize(window.innerWidth),
        },
        titleAlign: 'center',
        backgroundColor: 'rgba(37,37,37,0.75)',
      },
      title: {
        display: false,
        text: `동파경보 현황 ( 총 ${freezeWaringDataTotal}건 )`,
        font: {
          size: windowWidthResize(window.innerWidth),
        },
        position: 'top',
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'center',
        anchor: 'center',
        font: {
          size: windowWidthResize(window.innerWidth),
          weight: 'bold',
        },
        formatter: function (value: any, context: any) {
          const percentage = (value / freezeWaringDataTotal) * 100 // 비율을 계산

          if (percentage >= 5) {
            return value + '건' // 5% 이상이면 라벨 표시
          } else {
            return null // 5% 미만이면 라벨 표시하지 않음
          }
        },
      },
    },
  }

  const abnormalDataChartOptions = {
    responsive: true,
    aspectRatio: 1,
    onClick(event?: MouseEvent, activeElements?: Array<{}>): any {
      console.log(event)
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: function (context: any) {
            return context?.raw + '건'
          },
          title: function (context: any) {
            return context[0].label
          },
        },
        bodyFont: {
          size: windowWidthResize(window.innerWidth),
        },
        bodyAlign: 'center',
        bodyClassName: 'align-self-center mx-2',
        titleFont: {
          size: windowWidthResize(window.innerWidth),
        },
        titleAlign: 'center',
        backgroundColor: 'rgba(37,37,37,0.75)',
      },
      title: {
        display: true,
        text: `점검 필요 현황 ( 총 ${loading ? abnormalDataTotal : 0}건 )`,
        font: {
          size: windowWidthResize(window.innerWidth),
        },
        position: 'top',
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'center',
        anchor: 'center',
        font: {
          size: windowWidthResize(window.innerWidth),
          weight: 'bold',
        },
        formatter: function (value: any, context: any) {
          if (value >= 5) {
            return value + '건' // 5% 이상이면 라벨 표시
          } else {
            return null // 5% 미만이면 라벨 표시하지 않음
          }
        },
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  }
  const updateChartData = (field: any, newData: any) => {
    setChartData((prevChartData: any) => ({...prevChartData, [field]: newData}))
  }
  const getDeviceStatus = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo?.value
        }/dashboard/devicestatus`
      )
      .then((res) => {
        updateChartData('deviceStatus', res.data.res)
      })
  }
  const getDeviceBatteryStatus = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo?.value
        }/dashboard/devicebattery`
      )
      .then((res) => {
        updateChartData('batteryStatus', res.data.res)
      })
  }
  const getDeviceTypeStatus = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo.value
        }/dashboard/devicetype`
      )
      .then((res) => {
        updateChartData('deviceTypeStatus', res.data.res)
      })
  }
  const getFrozenStatus = () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo.value
        }/dashboard/freezewarningcount`
      )
      .then((res) => {
        updateChartData('frozenStatus', res.data.res)
      })
  }

  const getAbnormalStatus = () => {
    setLoading(false)
    axiosWithAuth()
      .get(
        `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
          cooperInfo.value
        }/dashboard/leakandlongtermcount`,
        {
          params: filter,
        }
      )
      .then((res) => {
        updateChartData('abnormalStatus', res.data.res)
        setLoading(true)
      })
  }

  useEffect(() => {
    if (entimoreController.model !== '2') {
      getFrozenStatus()
    }
    if (leakModelController.model === '2') {
      getAbnormalStatus()
    }
    if (entimoreController.model !== '2') {
      getDeviceStatus()
    }

    getDeviceBatteryStatus()
    getDeviceTypeStatus()
  }, [officeInfo, cooperInfo])

  return (
    <div className={'card card-body w-100 my-5 p-0 mx-0 d-flex col'} style={{background: 'none'}}>
      <div className={'d-flex px-0 mx-0 gap-4  col'}>
        <div className={'card card-body align-items-center col p-1'} style={{flex: 1}}>
          <div className={'row  gap-2 col justify-content-center align-items-center'}>
            <div
              className={'d-grid align-self-center col cursor-pointer'}
              id={'deviceStatus-chart'}
              style={{width: 300, height: 300, placeContent: 'center'}}
            >
              <Doughnut data={DeviceStatusdata} options={deviceChartOptions as any} />
            </div>
            <div className={'row align-items-center gap-2'} id={'deviceStatus-info'}>
              {_.map(DeviceStatusdata.labels, (el, i) => {
                return (
                  <div
                    className={'w-100 d-grid '}
                    style={{justifyItems: 'center', marginLeft: '30px'}}
                  >
                    <div
                      className={' d-flex gap-2 justify-content-start fs-3 fw-bold'}
                      style={{
                        cursor: 'pointer',
                        fontSize: windowWidthResize(window.innerWidth),
                        width: 300,
                      }}
                      onClick={async () => {
                        //should change
                        if (userInfo?.accountType?.includes('sys')) {
                          navigate('/customer/info', {
                            state: {
                              deviceStatus: el === '정상' ? 'true' : 'false',
                            },
                          })
                        } else if (userInfo?.accountType?.includes('gov_admin')) {
                          navigate('/customer/customer-status', {
                            state: {
                              deviceStatus:
                                entimoreController.model === '2'
                                  ? 'all'
                                  : el === '정상'
                                  ? 'true'
                                  : 'false',
                            },
                          })
                        } else {
                          await normalAlert('권한이 없습니다.')
                          return
                        }
                      }}
                    >
                      <SquareIcon style={{color: DeviceStatusdata.datasets[0].borderColor[i]}} />
                      <span>
                        {el} ( {DeviceStatusdata?.datasets[0].data[i]} 건 )
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={'card card-body align-items-center col p-0'} style={{flex: 1}}>
          <div className={'row gap-2 col justify-content-center align-items-center'}>
            <div
              className={'d-grid align-self-center col cursor-pointer'}
              id={'deviceBattery-chart'}
              style={{width: 300, height: 300, placeContent: 'center'}}
            >
              <Doughnut data={batteryChartData} options={batteryChartOptions as any} />
            </div>
            <div className={'row  align-items-center gap-2'} id={'deviceBattery-info'}>
              {_.map(batteryChartData.labels, (el, i) => {
                return (
                  <div
                    className={'w-100 d-grid '}
                    style={{justifyItems: 'center', marginLeft: '30px'}}
                  >
                    <div
                      className={'d-flex gap-2 justify-content-start fw-bold'}
                      style={{
                        cursor: 'pointer',
                        fontSize: windowWidthResize(window.innerWidth),
                        width: 300,
                      }}
                      onClick={async () => {
                        if (userInfo?.accountType?.includes('sys')) {
                          navigate('/customer/info', {
                            state: {
                              batteryStatus: el === '정상' ? 'true' : 'false',
                            },
                          })
                        } else if (userInfo?.accountType?.includes('gov_admin')) {
                          navigate('/customer/customer-status', {
                            state: {
                              batteryStatus: el === '정상' ? 'true' : 'false',
                            },
                          })
                        } else {
                          await normalAlert('권한이 없습니다.')
                          return
                        }
                      }}
                    >
                      <SquareIcon style={{color: batteryChartData.datasets[0].borderColor[i]}} />
                      <span>
                        {el} ( {batteryChartData?.datasets[0]?.data[i]} 건 )
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={'card card-body align-items-center col p-0'} style={{flex: 1}}>
          <div className={'row  gap-2 col justify-content-center align-items-center'}>
            <div
              className={'d-grid align-self-center col cursor-pointer'}
              id={'deviceType-chart'}
              style={{width: 300, height: 300, placeContent: 'center'}}
            >
              <Doughnut data={DeviceTypeData} options={deviceTypeCahrtOptions as any} />
            </div>
            <div className={'row gap-2 align-items-center'} id={'deviceType-info'}>
              {_.map(DeviceTypeData?.labels, (el, i: any) => {
                return (
                  <div
                    className={'w-100 d-grid '}
                    style={{justifyItems: 'center', marginLeft: '30px'}}
                  >
                    <div
                      className={'d-flex gap-2 justify-content-start fw-bold'}
                      style={{
                        cursor: 'pointer',
                        fontSize: windowWidthResize(window.innerWidth),
                        width: 300,
                      }}
                      onClick={async () => {
                        if (userInfo?.accountType?.includes('sys')) {
                          navigate('/customer/info', {
                            state: {
                              deviceType: el === '디지털이미지' ? 'analog' : 'digital',
                            },
                          })
                        } else if (userInfo?.accountType?.includes('gov_admin')) {
                          navigate('/customer/customer-status', {
                            state: {
                              deviceType: el === '디지털이미지' ? 'analog' : 'digital',
                            },
                          })
                        } else {
                          await normalAlert('권한이 없습니다.')
                          return
                        }
                      }}
                    >
                      <SquareIcon style={{color: DeviceTypeData?.datasets[0].borderColor[i]}} />
                      <span>
                        {el} ( {DeviceTypeData?.datasets[0].data[i]} 건 )
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className={'card card-body align-items-center col p-0'} style={{flex: 1}}>
          <div className={'row  gap-2 col justify-content-center align-items-center'}>
            <div
              className={'d-grid align-self-center col cursor-pointer pt-2'}
              id={'deviceType-chart'}
              style={{width: 300, height: 250, placeContent: 'center'}}
            >
              <>
                <FreezeTitle />
                <Doughnut data={freezeWarningData} options={freezeWaringCahrtOptions as any} />
              </>
            </div>
            <div className={'row gap-2 align-items-center'} id={'deviceType-info'}>
              {_.map(freezeWarningData?.labels, (el, i: any) => {
                return (
                  <div
                    className={'w-100 d-grid '}
                    style={{justifyItems: 'center', marginLeft: '30px'}}
                  >
                    <div
                      className={'d-flex gap-2 justify-content-start fw-bold fs-3'}
                      style={{
                        cursor: 'pointer',
                        fontSize: windowWidthResize(window.innerWidth),
                        width: 280,
                      }}
                      onClick={async () => {}}
                    >
                      <SquareIcon style={{color: freezeWarningData?.datasets[0].borderColor[i]}} />
                      <span>
                        {el} ( {freezeWarningData?.datasets[0]?.data[i]} 건 )
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {leakModelController.model === '2' && (
          <>
            {officeInfo?.value !== 'yit' && (
              <div className={'card card-body align-items-center col p-0'} style={{flex: 1}}>
                <div className={'row  gap-2 col justify-content-center align-items-center'}>
                  {!loading ? (
                    <span className='spinner-border align-middle ms-2 text-primary'></span>
                  ) : (
                    <>
                      <div
                        className={'d-grid align-self-center col cursor-pointer'}
                        id={'deviceType-chart'}
                        style={{width: 300, height: 300, placeContent: 'center'}}
                      >
                        <Bar data={abnormalData} options={abnormalDataChartOptions as any} />
                      </div>
                      <div className={'row gap-2 align-items-center'} id={'deviceType-info'}>
                        {_.map(abnormalData?.labels, (el, i: any) => {
                          return (
                            <div className={'w-100 d-grid '} style={{justifyItems: 'center'}}>
                              <div
                                className={'d-flex gap-2 justify-content-start fw-bold fs-2'}
                                style={{
                                  cursor: 'pointer',
                                  fontSize: windowWidthResize(window.innerWidth),
                                  width: 240,
                                }}
                                onClick={async () => {
                                  if (el === '누수의심') {
                                    navigate('/customer/customer-leakage')
                                  }
                                  if (el === '장기미사용') {
                                    navigate('/customer/unused')
                                  }
                                  // navigate(el.path)
                                }}
                              >
                                <SquareIcon
                                  style={{color: abnormalData?.datasets[0]?.borderColor[i]}}
                                />
                                <span>
                                  {el} ({loading ? abnormalData?.datasets[0]?.data[i] : 0} 건 )
                                </span>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardChart
